import React, { FC } from 'react';
import { graphql, Link } from 'gatsby';
import { useLocation } from '@reach/router';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import Layout from 'layout/Layout';
import Image from 'common/Image';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Footer from 'components/Footer';
import Header from 'components/Header';

import { RecipeListProps } from './models';

import './RecipeList.scss';

const RecipeList: FC<{ data: RecipeListProps }> = ({
  data: {
    recipeList: { imageDesktop, imageMobile, urls, seo, seeMore, pixel },
    allRecipe: { nodes },
    footer,
    header,
    siteSettings,
  },
}) => {
  const { title: seoTitle, keywords: seoKeywords, description: seoDescription } = seo;
  const location = useLocation();
  const eventData = { eventType: 'page_view', tagData: { pageTemplate: location.pathname } };

  return (
    <Layout headerTransparent {...{ pixel }}>
      {header && <Header data={header} settings={siteSettings} />}
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebSite"
        name={seoTitle}
        data={{
          metaTitle: seoTitle,
          metaDescription: seoDescription,
          metaKeywords: seoKeywords,
        }}
        tagEventData={eventData}
      />
      <div className="mx-auto section-container recipe-list">
        <section className="recipe-list__banner">
          <Image
            imageData={imageDesktop}
            alt={imageDesktop.altText}
            className="d-none d-md-block"
          />
          <Image imageData={imageMobile} alt={imageMobile.altText} className="d-block d-md-none" />
        </section>
        <section className="recipe-list__items">
          <div className="container">
            <div className="row pb-5">
              {nodes.map(({ id, title, image, url }) => (
                <div className="col-12 col-md-4 my-2" key={id}>
                  <div className="recipe-list__item mx-auto">
                    <div className="p-1 recipe-list__item-image">
                      <Image imageData={image} alt={image.altText} />
                    </div>
                    <div className="px-3 pb-3">
                      <DangerouslySetInnerHtml html={title} element="h3" className="py-4" />
                      <div className="text-end">
                        <Link to={url} className="btn btn-primary">
                          {seeMore}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
      {footer ? <Footer data={footer} /> : <></>}
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String) {
    recipeList {
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
      seeMore
      imageDesktop {
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1300)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1300)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1300)
          }
        }
      }
      imageMobile {
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 800)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 800)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 800)
          }
        }
      }
      pixel {
        nameEvent
        contentId
        contentName
        contentType
        contentValue
        contentCurrency
      }
    }
    allRecipe {
      nodes {
        id
        title
        url
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    header(lang: { eq: $lang }) {
      logo {
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 200)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 70)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 70)
          }
        }
      }
      socialMedia {
        name
        url
        queryString
      }
      navs {
        name
        url
        queryString
      }
      sidebar {
        url {
          url
        }
        image {
          desktop {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 60)
            }
          }
          mobile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 60)
            }
          }
          tablet {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 60)
            }
          }
        }
        url {
          url
        }
      }
    }
    siteSettings {
      siteName
      defaultLang
      popupLanguage
      avaliableLanguages {
        url
        code
        title
        longTitle
      }
    }
    footer(lang: { eq: $lang }) {
      logo {
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 150)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 150)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 150)
          }
        }
      }
      extraLogo {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      navs {
        name
        url
      }
      contact
      copyright
      socialMedia {
        iconImage {
          url
          altText
          fallbackUrl
        }
        iconLink {
          url
          name
          icon
        }
      }
    }
  }
`;

export default RecipeList;
